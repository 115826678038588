import React, { useState } from 'react';
import { Container, Row, Col, Collapse, Button, CardBody, Card, CardImg, CardText, CardTitle, CardSubtitle, CardLink } from 'reactstrap';
import './App.css';

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    
    <div className="App">
      <div id="top-bar">
      </div>
      <div id="menu-container">
      <Container>
        <Row>
          <Col xs={12}>
              <Collapse isOpen={isOpen}>
                <Card style={{ border: 'none' }}>
                  <CardBody style={{ paddingBottom: 0 }}>
                    <Row>
                      <Col xs={3}>
                        <Button color="secondary" size="md" block outline>Artists</Button>
                      </Col>
                      <Col xs={3}>
                        <Button color="secondary" size="md" block outline>Stream</Button>
                      </Col>
                      <Col xs={3}>
                        <Button color="secondary" size="md" block outline>Store</Button>
                      </Col>
                      <Col xs={3}>
                      <Button color="secondary" size="md" block outline>Sign-Up</Button>
                      </Col>
                      
                      
                      
                    </Row>
                  </CardBody>
                </Card>
              </Collapse>
          </Col>
        </Row>

        <Row>
          <Col xs={1}>
            <div id="menu">
              <Button color="primary" onClick={toggle} id="menu-toggle">
              {
                isOpen ?
                <svg xmlns="http://www.w3.org/2000/svg" color="#ff6b6b" width="2.5em" height="2.5em" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
              }
              </Button>

            </div>
          </Col>

          <Col xs={9}>
              <div className="cursive">
                <h1 id="logo">real nice records</h1>
              </div>
          </Col>

        </Row>
      </Container>
      </div>

        <div id="Content">
          <Container>
          <Row>
            <Col xs={12} md={6}>
              <Card>
                <CardImg top width="100%" src="../RealNiceGuys.png" alt="Card image cap" />
                <CardBody>
                  <CardTitle tag="h5">Real Nice Guys</CardTitle>
                  <CardSubtitle tag="h6" className="mb-2 text-muted"><em>"Real Nice, Guys!" Now Available</em></CardSubtitle>
                  <CardText>
                    Let RNG take you on a playful romp through our troubled times with the innocent perspective of a real nice guy just trying to exist peacefully amid the chaos of a world in flames.
                  </CardText>
                  <CardLink href="/RealNiceGuys">View Artist</CardLink>
                  <CardLink href="https://open.spotify.com/artist/6NtJSpbLAsmNQybFG6tzyR" target="_blank" rel="noreferrer">Stream on Spotify</CardLink>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <CardImg top width="100%" src="../griller.png" alt="Card image cap" />
                <CardBody>
                  <CardTitle tag="h5">Late Night Griller</CardTitle>
                  <CardText>
                    "<em>It's Okay Not to Feel like Everyone Else Sometimes</em>" is the debut single from <strong><em>Late Night Griller</em></strong> now available for streaming and download.
                    <br /><br />
                  </CardText>
                  <CardLink href="/LateNightGriller">View Artist</CardLink>
                  <CardLink href="#" target="_blank" rel="noreferrer">Stream on Spotify</CardLink>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Card>
                <CardImg top width="100%" src="../beargod.jpg" alt="Card image cap" />
                <CardBody>
                  <CardTitle tag="h5">Beargod</CardTitle>
                  <CardText>
                    Available as of August 28, 2017
                    <br /><br />
                  </CardText>
                  <CardLink href="/Bearbones">View Artist</CardLink>
                  <CardLink href="#" target="_blank" rel="noreferrer">Stream on Spotify</CardLink>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <CardImg top width="100%" src="../bwfh.jpg" alt="Card image cap" />
                <CardBody>
                  <CardTitle tag="h5">Big Warm Fuzzy Heart</CardTitle>
                  <CardText>
                    "Big Warm Fuzzy Heart" is available as of April 20, 2015
                    <br /><br />
                  </CardText>
                  <CardLink href="/Bearbones">View Artist</CardLink>
                  <CardLink href="#" target="_blank" rel="noreferrer">Stream on Spotify</CardLink>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </div>

      <div id="footer">
        &copy; 2021 <a href="http://www.spaceyyy.com/" target="_blank" rel="noreferrer">spaceyyy.com</a> / made with  <svg  color="#ff6b6b" xmlns="http://www.w3.org/2000/svg" width=".9em" height=".9em" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/></svg> in indianapolis
      </div>

    </div>
  );
}

export default App;
